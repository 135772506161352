<template>
  <div class="c-app" style="background-color: #ffffff">
    <div class="c-wrapper">
      <div class="c-body">
        <div class="lds-ripple">
          <div></div>
          <div></div>
        </div>
        <p style="z-index: 1090;position: fixed; top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);margin-top: 80px;" class="font-weight-bold text-color-theme text-center">{{
    $t('sendingOrder')
          }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import poscrm from '@/services/poscrm'
import orderutil from '@/util/order'
import '@/util/loader.css'
import qs from 'qs'
import realtime from '@/util/checkActiveReceipts'

export default {
  data() {
    return {
      cartLists: [],
      table: {},
      receiptItems: [],
      receiptId: '',
      receiptObjectId: '',
    }
  },
  computed: {
    ...mapGetters(['shopLineOA']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopObjectId() {
      return `${localStorage.getItem('shopObjectId')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopObjectId
      )
    },
    tableObjectId() {
      return `${localStorage.getItem('tableObjectId')}`
    },
  },

  created() {
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
    this.table = JSON.parse(localStorage.getItem('table')) || []
    this.receiptId = localStorage.getItem('receiptId') || ''
    this.receiptObjectId = localStorage.getItem('receiptObjectId') || ''
    this.getTableStatusFromFB()
  },
  methods: {
    trackButtonClick(buttonLabel) {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'click', {
          event_label: buttonLabel,
        });
      } else {
        console.error('gtag is not defined.');
      }
    },
    async createOrder() {
      this.trackButtonClick('Pending/CreateOrder')
      const timestamp = localStorage.getItem('timestamp')
      const pax = localStorage.getItem('pax')

      const data = orderutil.orderDineIn(
        this.uid,
        this.shop,
        this.cartLists,
        this.table,
        timestamp,
        pax
      )

      try {
        await poscrm({
          url: '/api/v1.1/' + this.uid + '/Receipt/createorder',
          data: data,
          method: 'post',
        }).then((res) => {
          if (res.data.error.code === 0) {
            const receiptId = res.data.data.id
            const receiptObjectId = res.data.data.objectId

            localStorage.setItem('receiptId', receiptId)
            localStorage.setItem('receiptObjectId', receiptObjectId)
            this.updateTableStatus(receiptId, receiptObjectId)
          } else {
            sessionStorage.clear()
            localStorage.clear()
            this.$router.push('/texp?errorCode=-4001')
          }
        })
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateOrder() {
      this.receiptItems.push(...this.cartLists)

      let receiptObjectId = localStorage.getItem('receiptObjectId')
      let timestamp = localStorage.getItem('timestamp')

      let data = {
        objectId: receiptObjectId,
        uid: this.uid,
        shopObjectId: this.shopObjectId,
        receipt: {
          source: 'SELF_ORDER',
          QRTimestamp: timestamp,
          receiptStatus: 'O',
          table: {
            id: this.table.id,
            objectId: this.table.objectId,
            name: this.table.name,
          },
          receiptItems: this.receiptItems,
          objectId: this.receiptObjectId,
          id: this.receiptId,
          isVATIncluded: true,
        },
      }

      try {
        await poscrm({
          url: '/api/v1.1/' + this.uid + '/Receipt/updateorder',
          data: data,
          method: 'post',
        }).then((res) => {
          let response = {}
          response = res || {}

          if (response.data?.error?.code === 0) {
            let receiptId = response.data?.data?.id
            let receiptObjectId = response.data?.data?.objectId

            localStorage.setItem('receiptId', receiptId)
            localStorage.setItem('receiptObjectId', receiptObjectId)

            this.updateTableStatus(receiptId, receiptObjectId)
          } else {
            //alert('เกิดข้อผิดพลาด ติดต่อพนักงานของเราเพื่อขอความช่วยเหลือ')
            sessionStorage.clear()
            localStorage.clear()
            this.$router.push('/texp?errorCode=-4002')
          }
        })
      } catch (error) {
        throw new Error(error);
      }

    },
    async updateTableStatus(receiptId, receiptObjectId) {
      const uid = this.uid
      let shopObjectId = this.shopObjectId

      let data = {
        shopObjectId: shopObjectId,
        tableObjectId: this.table.objectId,
        status: 0,
        receiptId: receiptId,
        receiptObjectId: receiptObjectId,
      }

      try {
        await poscrm({
          url: '/api/v1.1/' + uid + '/Shop/updatetablestatus',
          data: qs.stringify(data),
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then((res) => {
          sessionStorage.clear()
          this.$router.push('/dine-in/mycart?redirect=pending')
        })
      } catch (error) {
        throw new Error(error);
      }
    },
    async getReceiptTransaction() {
      await poscrm.get(`/api/v1.1/${this.uid}/Receipt/data/${this.receiptObjectId}`)
        .then((res) => {
          const document = res.data.data.document
          const receiptItems = document.receiptItems || []
          const table = document.table || []
          const tableStatus = table.tableStatus || ''

          if (receiptItems.length === 0) {
            this.createOrder();
          } else if (tableStatus === 'P') {
            this.$router.push('/dine-in/pending/payment')
          } else {
            this.updateOrder()
          }
        })
        .catch((error) => {
          throw new Error(error);
        })
    },
    async getTableStatusFromFB() {
      let params = {
        shopObjectId: this.shopObjectId,
        objectId: this.tableObjectId,
      }
      await poscrm
        .get('/api/v1.1/' + this.uid + '/Shop/gettablebyobjectid/fbdata', {
          params: params,
        })
        .then((res) => {
          if (res.data.data.currentReceipt != undefined) {
            let currentReceipt = res.data.data.currentReceipt
            this.receiptId = currentReceipt.id
            this.receiptObjectId = currentReceipt.objectId
            localStorage.setItem('receiptId', this.receiptId)
            localStorage.setItem('receiptObjectId', this.receiptObjectId)
            this.getReceiptTransaction()
          } else {
            this.createOrder()
          }
        }).catch((error) => {
          throw new Error(error);
        })
    },
  },
}
</script>
<style>
.text-color-theme {
  color: var(--bg-color-theme);
}
</style>